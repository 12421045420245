@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    
    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply font-normal;
    }

    h1 {
        @apply text-4xl;
    }

    section {
        @apply mt-32;
    }

    section > h2 {
        @apply mb-12 text-2xl text-daisy text-center font-bold;
    }

    section > h5 {
        @apply text-gray-400 pt-6 mb-2 text-sm text-center;
    }

    a {
        @apply inline-block text-daisy hover:text-white transition-all duration-300 ease-linear;
    }

    nav a {
        @apply p-2 sm:p-3 rounded-full text-gray-400 text-lg hover:bg-[#555555];
    }

    nav .active {
        @apply text-daisy bg-[#666666];
    }

    .btn {
        @apply max-w-max inline-block text-daisy hover:text-night hover:bg-daisy px-4 py-2 border border-daisy hover:border-transparent rounded-full transition-all duration-300 ease-linear;
    }

    .btn-primary {
        @apply text-night bg-daisy hover:bg-white;
    }

    .portfolio_item {
        @apply bg-daisy-500 p-5 border border-transparent rounded-xl duration-300 hover:bg-transparent hover:border-daisy-100 hover:cursor-default max-sm:mx-8 text-center;
    }

    .portfolio_item_img {
        @apply rounded-lg overflow-hidden;
    }

    .portfolio_item h3 {
        @apply mt-8 mb-5;
    }

    .portfolio_item_lnk {
        @apply flex w-full mt-8 justify-center;
    }
} 